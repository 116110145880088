import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout/Layout";

export const ContactTemplate = ({}) => <div className="content">Contact</div>;

ContactTemplate.propTypes = {};

const ContactPage = () => {
  return (
    <Layout>
      <ContactTemplate />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ContactPage;
